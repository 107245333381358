import React from 'react';
import { graphql } from 'gatsby';
import tw, { styled, css } from 'twin.macro';
import { withArtDirection, getImage, GatsbyImage } from 'gatsby-plugin-image';
import Sandbox from '../atoms/sandbox';
import FloatingBox from '../common/floating-box';
import Breadcrumb from '../atoms/breadcrumb';

const mqs = {
  portrait: '(min-width: 568px)',
};

const FeatureImageFloatingBox = styled(FloatingBox)`
  @media ${mqs.portrait} {
    ${tw`w-2/5 flex-shrink-0 flex-grow-0 order-2 -mt-24`}
  }
`;

const FeatureImage = styled(GatsbyImage)`
  ${tw`!absolute !inset-0`}
`;

const FeatureText = ({ data }) => {
  const images =
    data.square &&
    withArtDirection(getImage(data.square.localFile), [
      {
        media: mqs.portrait,
        image: getImage(data.portrait.localFile),
      },
    ]);

  return (
    <div
      css={[
        tw`relative p-6 bg-brand-purple-200`,
        tw`sm:(px-20)`,
        tw`lg:(px-28)`,
        tw`xl:(px-48)`,
      ]}
    >
      <div tw="max-w-screen-lg mx-auto">
        {data.breadcrumb && (
          <Breadcrumb text={data.breadcrumb} tw="text-center" />
        )}
        <div
          css={css`
            @media ${mqs.portrait} {
              ${tw`flex items-center w-full`}
            }
          `}
        >
          {images && (
            <FeatureImageFloatingBox range={0.2}>
              <div
                css={[
                  css`
                    position: relative;
                    padding-bottom: 100%;
                    @media ${mqs.portrait} {
                      padding-bottom: ${(16 / 9) * 100}%;
                    }
                  `,
                ]}
              >
                <FeatureImage
                  image={images}
                  alt={data.square.alternativeText}
                />
              </div>
            </FeatureImageFloatingBox>
          )}
          <div
            css={[
              tw`px-4 py-6 bg-brand-purple-200 z-30 relative`,
              css`
                @media ${mqs.portrait} {
                  ${tw`flex-grow order-1 pl-0 py-8 bg-transparent`}
                }
              `,
              tw`lg:pr-16 xl:(pr-20 py-12) 2xl:(py-20)`,
            ]}
          >
            <div tw="text-2xl leading-none font-black text-brand-green mb-4 sm:(text-3xl mb-6) lg:text-4xl">
              {data.title}
            </div>
            <Sandbox
              html={data.intro}
              tw="text-brand-purple-800 xl:(text-xl leading-relaxed) 2xl:(text-2xl leading-relaxed)"
            />
            <Sandbox
              html={data.body}
              tw="text-brand-purple-800 mt-6 xl:(text-lg leading-relaxed) 2xl:(text-xl leading-relaxed)"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FeatureText on STRAPI_ComponentContainersFeatureText {
    id
    title
    intro
    body
    breadcrumb
    square: image {
      url
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 1
            width: 568
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    portrait: image {
      url
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 0.5625
            width: 480
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;

export default FeatureText;
