import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import {
  useViewportScroll,
  useTransform,
  useSpring,
  motion,
} from 'framer-motion';

import rand from 'lodash/random';

const FloatingBox = ({
  children,
  topOffset = -500,
  bottomOffset = 500,
  range = 0.2,
  ...props
}) => {
  const { scrollY } = useViewportScroll();
  const ref = useRef();

  const [elementTop, setElementTop] = useState(0);
  const springConfig = {
    damping: 100,
    stiffness: 100,
    mass: rand(1, 3),
  };

  useLayoutEffect(() => {
    if (!ref.current) return;
    const onResize = () => {
      setElementTop(ref.current.offsetTop);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [ref, range]);

  const y = useSpring(
    useTransform(
      scrollY,
      [elementTop + topOffset, elementTop + bottomOffset],
      [`-${range * 100}%`, `${range * 100}%`]
    ),
    springConfig
  );

  return (
    <motion.div ref={ref} initial={{ y: 0 }} style={{ y }} {...props}>
      {children || ''}
    </motion.div>
  );
};

const FloatingBoxWrapper = (props) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);
  if (!show) return props.children || null;
  return <FloatingBox {...props} />;
};

export default FloatingBoxWrapper;
